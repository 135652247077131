<template>
  <component
    :is="tag"
    :href="href"
    class='klubba-button'
    :class="[customClass, `theme-${theme}`, { 'is-disabled': disabled, 'is-loading': loading }]"
  >
    <KlubbaLoaderIcon light :active="loading" />

    <transition name="fade">
      <div class="wrapper" v-if="!loading">
        <div>{{buttonText}}</div>
        <div :class='childClass' v-if='childClass'>
          <img
            :src="require(`@/assets/icons/${icon}`)"
            :class="iconClass"
          />
          <div>{{childText}}</div>
        </div>
      </div>
    </transition>
  </component>
</template>

<script>
import KlubbaLoaderIcon from './KlubbaLoaderIcon.vue';

export default {
  components: { KlubbaLoaderIcon },
  props: {
    buttonText: {
      type: String,
    },
    customClass: {
      type: String,
    },
    childClass: {
      type: String,
    },
    childText: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconClass: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    theme: {
      type: String,
      default: 'primary', // primary || danger || black
    },
    href: {
      type: String,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="sass" scoped>
.klubba-button
  @apply h-11 w-full flex items-center rounded-radius-10 justify-center cursor-pointer px-2 font-medium relative
  transition: opacity var(--transition)

  &.theme-primary
    color: var(--primary-fore-color, var(--klubba-primary-fore-color))
    background: var(--primary-back-color, var(--klubba-primary-back-color))

  &.theme-danger
    @apply text-white bg-danger

  &.theme-black
    @apply text-black bg-black

  .wrapper
    @apply flex items-center h-full justify-center

  &.is-loading
    pointer-events: none

  &.is-disabled
    opacity: .35
    pointer-events: none
</style>
